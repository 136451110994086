.page-wrapper {
  display: block;
}

.navHoverPointer {
  cursor: pointer;
}
.navHoverPointer :hover {
  color: black;
}

.react-international-phone-input {
  width: 100%;
}

.width-40 {
  width: 40px;
}
.admin-profile-round {
  border-radius: 50%;
}
.b {
  z-index: 10;
}

.db-search {
  left: 400px;
}

.sticky-col {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background-color: white; /* Optional: to match the column's background */
  z-index: 1; /* Ensure it stays on top */
}

/* .phone-numbers-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  background-color: #f9f9f9;
} */

.phone-numbers-box {
  position: absolute;
  top: -60px; /* Adjust this value as needed */
  left: 0;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  z-index: 1000; /* Ensure it appears above other elements */
}

.hoverable-number {
  color: initial; /* Initial color */
  transition: color 0.3s ease; /* Smooth transition */
}

.hoverable-number:hover {
  color: blue; /* Color on hover */
}

.black-border {
  border: 1px solid black;
}

.full-height {
  height: 100vh;
}

.iframe-full {
  width: 100%;
  height: 100%;
  border: none;
}

.hover-text:hover {
  color: #5f76e8 !important;
}

.rounded-4 {
  border-radius: 40px !important;
}

.font-weight-bold {
  font-weight: bold;
}

.contactProfile {
  width: 120px !important;
  height: 120px;
}

/* .profile-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 3px solid black;
} */

.profile-image {
  max-width: 150px;
  height: 150px;
  object-fit: cover;
  /* border: 3px solid black; */
 }

 .profile-image-spouse-consumer {
  max-width: 200px;
  height: 200px;
  object-fit: cover;
  /* border: 3px solid black; */
 }

 .react-international-phone-input-container {
  width: 90%;
 }


 .form-control-radio {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #4F5467;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid;
  border-radius: 2px;
  transition: border-color 0.15sease-in-out, box-shadow 0.15sease-in-out;
  margin: 0 auto; /* This centers it horizontally */
  text-align: center; 
}

/* Hide the country flag image */
.react-international-phone-flag-emoji {
  display: none !important;  /* Hide the flag image */
}

/* Optional: Remove extra padding where the flag was */
.react-tel-input input {
  padding-left: 0px;
}


/* .page-breadcrumb {
  position: fixed;
 
  height: 100%;
  padding: 30px 35px 0;
  z-index: 1000; 
  background-color: white; 
} */



.page-breadcrumb {
  position: fixed;
  padding: 30px 35px 0;
  z-index: 1000;
  background-color: white;
  /* margin-top: 0.5rem; */
}


.page-wrapper > .container-fluid {
  padding: 210px 35px;
  min-height: calc(100vh - 210px); }

  .form-control-contact{
    width: 150px;
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    color: #4F5467;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
.btns{width:285px; margin: auto;}
.btns .btn{width: 60px;height: 60px;border-radius: 50%;font-size: 30px;text-align: center;margin: 0 16px 16px;}


.call_btn{width:100%; margin: auto; padding: 0; font-size: 12px;}
.call_btn li{text-align: center;margin: 0; list-style: none; width: 16.66%; box-sizing: border-box;display: inline-block; white-space: nowrap;}
.call_btn li .btn{width: 44px;height: 44px;border-radius: 50%;text-align: center;margin: 0; color: #fff;}
.call_btn li .btn svg{width: 20px; height: 20px;}

.green_bg{background: #22ca7f;}
.red_bg{background: #fe4f70;}
.orange_bg{background: #fec627;}
.blue_bg{background: #0ba5d9;}
.green_dark_bg{background: #008c3a;}
.purple_bg{background: #d21976;}

/* Default Styles for Mobile Screens */
@media (max-width: 1440px) {
.call_btn li{width: 33.33%;}

}
@media (max-width: 991px) {
.call_btn li{width: 16.66%;}
}
  @media (max-width: 768px) {
    .page-breadcrumb {
      padding: 15px 20px 0;
      position: static;
    }
  
    .page-wrapper > .container-fluid {
      padding: 20px 20px;
      min-height: calc(100vh - 150px);
    }
  }
  @media (max-width: 414px) {
    .call_btn li{width: 33.33%;}
  }
  
  .refresh-icon {
    transition: color 0.3s ease;
  }
  
  .refresh-icon:hover {
    color: black; /* Change to black when hovered */
  }
  